import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import commonApi from "./commonApi";

Vue.config.productionTip = false;
// 移动端适配
// import 'lib-flexible/flexible';

import '@/utils/rem.js'


import "./style/main.scss";

import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'


Vue.use(ElementUI).use(VideoPlayer);

Vue.prototype.trim = function (s) {
  return s.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
};
// Vue.prototype.$commonApi = commonApi;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
