<!--
 * @Author: Tang
 * @Date: 2020-10-27 09:35:31
-->
<template>
  <div id="app">
    <router-view> </router-view>
    <div class="bg-red-900" alt="" src="" />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
};
</script>

<style>
#app {
  width: 100%;
  overflow-x: hidden;
}
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.placeholder_top {
  position: fixed;
  top: 0;
  left: 0;
  width: 10rem;
  background-color: #303030;
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  z-index: 999;
}
</style>
