import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // openId: '', //此处做用户是否登陆判断标识
    // isLogin: 0, // 0:未登录 1:已登录
  },
  // getters: {
  //   getStorage: function (state) {
  //     if (!state.openId) {
  //       state.openId = JSON.parse(localStorage.getItem(openId))
  //       state.isLogin = localStorage.getItem(isLogin)
  //     }
  //     return state.openId
  //   }
  // },

  mutations: {
    // setIsLogin: function (state, value) {
    //   state.isLogin = value
    // },
    // setStorage(state, value) {
    //   state.openId = value
    //   localStorage.setItem(openId, value)
    // },
    // removeStorage(state) {
    //   state.openId = null
    //   localStorage.removeItem(openId)
    // },
    
  },
  actions: {

  }
})
