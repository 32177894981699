import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    meta: {
      title: "畅购天下",
      description: "CNbuyers.cn - 是由浙江省属企业国贸集团旗下跨境进口购物商城，国家跨境贸易电子商务试点项目，为国内客户提供值得信赖的原装进口商品，让您足不出户就淘遍世界。"
    },
    component: () => import("@/views/home/index"),
  }
];

routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({ routes, mode: "history" });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // if (to.matched.some(m => m.meta.auth)) {
  //   if (window.localStorage.isLogin === 1) {
  //     next()
  //   } else if (to.path !== '/') {
  //     Notify({ type: 'primary', message: '请登录后操作' });
  //     next({ path: '/login' })
  //   }
  // } else {
  next();
  // }
});
export { router };
